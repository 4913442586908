@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap");

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Lato", sans-serif;
  margin:5%;
}

#overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  display: none;
}

#overlay img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 64px;
}

.mainbox {
 display: flex;

}

#table-section {
  width: 60%;
}

a {
  text-decoration: none;
}

form {
  border: 1px solid black;
  width: 230px;
  padding: 6px 10px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  margin: 0 auto;
  margin-bottom: 20px;
}

button,
input,
textarea {
  font-family: "Lato", sans-serif;
  outline: unset;
}

#info-wrapper {
  width: 40%;
  padding: 0 0 20px 0;
  text-align: center;
  font-size: 18px;
  padding: 20px;

}

h1 {
  font-weight: 900;
  letter-spacing: 2px;
}

#info-wrapper p {
  font-style: italic;
  color: gray;
}

#info-content {
  display: block;
  margin-top: 10px;
}

#info-wrapper > div {
  margin-bottom: 5px;
}

textarea {
  white-space: normal;
  width: 100%;
  z-index: 2;
  margin: 0;
  border: 2px solid black;
  border-radius: 0;
  color: #444;
  background-color: transparent;
  overflow: auto;
  resize: none;
  font-size: 16px;
  padding: 5px;
  border-radius: 5px;
}

input {
  font-size: 16px;
  border: unset;
  width: 100%;
}

input:focus::placeholder,
textarea:focus::placeholder {
  color: transparent;
}

img {
  width: 16px;
  margin-right: 10px;
  margin-left: 5px;
}

#table-wrapper {
  box-shadow: 0 0px 40px 0px rgba(0, 0, 0, 0.15);
}

#table-headers {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden;
}

#table-data {
  max-height: 500px;
  overflow-y: overlay;
}

@-moz-document url-prefix() {
  #table-data {
    overflow-y: scroll;
  }
}

#table-data tr {
  cursor: pointer;
  background-color: lightgray;
}

#table-data tr:nth-child(even) {
  background-color: white;
}

table {
  border-collapse: collapse;
  width: 100%;
  font-size: 14px;
  text-align: center;
  border-radius: 10px;
}

th {
  font-size: 18px;
  color: #fff;
  line-height: 1.4;
  background-color: black;
  padding-top: 18px;
  padding-bottom: 18px;
}

td {
  font-size: 16px;
  color: #808080;

  line-height: 1.4;
  padding-top: 16px;
  padding-bottom: 16px;
}

.column1 {
  width: 50px;
}

.column2 {
  width: 170px;
}

.column3 {
  width: 170px;
}

.column4 {
  width: 170px;
}

.column5 {
  width: 300px;
}

.active {
  background-color: lightseagreen !important;
}

.active td {
  color: black;
}